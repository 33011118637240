import React, { Fragment } from "react";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Mission from "../../components/Mission";

const MissionPage = () => {
  return (
    <Fragment>
      <PageTitle pageTitle={"Mission"} pagesub={"Mission"} />
      <Mission />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default MissionPage;
