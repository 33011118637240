import React, { Fragment } from "react";
import PageTitle from "../../components/pagetitle";
import Scrollbar from "../../components/scrollbar";
import EventTabs from "./alltab";
import Footer from "../../components/footer";
import { useParams } from "react-router-dom";

const EventSinglePage2 = (props) => {
  const { id } = useParams();

  return (
    <Fragment>
      <PageTitle pageTitle={"Importent Message for you"} pagesub={"Message"} />
      <div className="wpo-event-details-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-lg-8">
              <div className="wpo-event-item">
                {/* <div className="wpo-event-img">
                  <img src={eventDetails.eImg} alt="" />
                </div>
                <div className="wpo-event-details-text">
                  <h2>{eventDetails.eTitle}</h2>
                  <p>
                    On the other hand, we denounce with righteous indignation
                    and dislike men who are so beguiled and demoralized by the
                    charms of pleasure of the moment, so blinded by desire, that
                    they cannot foresee the pain and trouble that are bound to
                    ensue and equal blame belongs to those who fail in their
                    duty through weakness of will, which is the same as saying
                    through shrinking from toil and pain.
                  </p>
                </div> */}
                <EventTabs id={id} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default EventSinglePage2;
