import React, { Fragment } from "react";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Performance from "../../components/Performance";

const PerformancePage = () => {
  return (
    <Fragment>
      <PageTitle pageTitle={"Performance"} pagesub={"About us"} />
      <Performance />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default PerformancePage;
